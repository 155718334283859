import React, { Component } from "react"
import Modal from "react-modal"
import PropTypes from "prop-types"
import { FormErrors } from "./formErrors"
import * as emailjs from "emailjs-com"

const DEFAULT_TITLE = "Default title"

class Forms extends Component {
  static propTypes = {
    btnLabel: PropTypes.string.isRequired,
    thankYouMsg: PropTypes.string.isRequired,
    closeLbl: PropTypes.string.isRequired,
    emailjstemplate: PropTypes.string.isRequired,
    emailjsuser: PropTypes.string.isRequired,
  }
  state = {
    senderEmail: "",
    formSubmitted: false,
  }

  handleCancel = this.handleCancel.bind(this)
  handleChange = this.handleChange.bind(this)
  handleSubmit = this.handleSubmit.bind(this)

  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
      formSubmitted: false,
      senderEmail: "",
      formErrors: { email: "" },
      emailValid: false,
      formValid: false,
    }
  }

  //open modal pop up for inquiry form
  toggleModal = event => {
    const { isOpen } = this.state
    this.setState({ isOpen: !isOpen })
  }

  handleCancel() {
    this.setState({
      senderEmail: "",
    })
  }

  handleChange(event) {
    this.handleUserInput(event)
    this.setState({
      senderEmail: event.target.value,
    })
  }

  //submit sending the email
  handleSubmit(event) {
    event.preventDefault()
    this.sendInquiry(
      this.props.emailjstemplate,
      this.state.senderEmail,
      this.props.productTitle,
      this.props.partNumber,
      this.props.productPrice,
      this.props.productVariant
    )

    this.setState({
      formSubmitted: true,
    })
  }

  sendInquiry(
    templateId,
    senderEmail,
    productTitle,
    partNumber,
    productPrice,
    productVariant
  ) {
    emailjs
      .send(
        "mailgun",
        templateId,
        {
          senderEmail,
          productTitle,
          partNumber,
          productPrice,
          productVariant,
        },
        this.props.emailjsuser
      )
      .then(res => {
        this.setState({ formEmailSent: true })
      })
      // Handle errors here however you like, or use a React error boundary
      .catch(err => console.error("Failed to send inquiry. Error: ", err))
  }

  //validation of senders email
  handleUserInput(e) {
    const name = e.target.name
    const value = e.target.value
    this.setState({ [name]: value }, () => {
      this.validateField(name, value)
    })
  }

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors
    let emailValid = this.state.emailValid

    switch (fieldName) {
      case "email":
        emailValid = value.match(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i
        )
        fieldValidationErrors.email = emailValid ? "" : " is invalid"
        break
      default:
        break
    }
    this.setState(
      { formErrors: fieldValidationErrors, emailValid: emailValid },
      this.validateForm
    )
  }

  validateForm() {
    this.setState({ formValid: this.state.emailValid })
  }

  errorClass(error) {
    return error.length === 0 ? "" : "has-error"
  }

  render() {
    const { isOpen } = this.state
    const {
      props: {
        btnLabel,
        productTitle,
        partNumber,
        productPrice,
        productVariant,
        emailLabel,
        submitMessage,
        submitLabel,
        thankYouMsg,
        closeLbl,
        emailjstemplate,
        emailjsuser
      },
    } = this

    return (
      <div>
        <button
          className="product-details green-btn"
          onClick={this.toggleModal}
        >
          {btnLabel}
        </button>
        <Modal
          id="modal_with_forms"
          isOpen={isOpen}
          closeTimeoutMS={150}
          contentLabel="modalB"
          shouldCloseOnOverlayClick={true}
          onRequestClose={this.toggleModal}
          aria={{
            labelledby: "heading",
            describedby: "fulldescription",
          }}
        >
          <div className="panel panel-default">
            <FormErrors formErrors={this.state.formErrors} />
          </div>
          <div id="fulldescription" tabIndex="0" role="document">
            {!this.state.formSubmitted && (
              <form className="inquiry-form" onSubmit={this.handleSubmit} data-netlify-recaptcha="true" data-netlify="true">
                <div className="product-name">{productTitle}</div>
                <div className="product-part-number">{partNumber}</div>
                {productVariant && (
                  <div
                    style={{
                      fontSize: "22px",
                      textTransform: "uppercase",
                      marginBottom: "30px",
                      color: "#525252",
                    }}
                  >
                    {productVariant}
                  </div>
                )}
                {!productVariant && (
                  <div
                    style={{
                      fontSize: "22px",
                      textTransform: "uppercase",
                      marginBottom: "30px",
                      color: "#525252",
                    }}
                  >
                    {productPrice}
                  </div>
                )}
                <h3>{emailLabel}</h3>
                <div
                  className={`form-group ${this.errorClass(
                    this.state.formErrors.email
                  )}`}
                >
                  <input
                    type="email"
                    onChange={this.handleChange}
                    value={this.state.senderEmail}
                    name="email"
                    className="form-control"
                  />
                </div>
                <p>{submitMessage}</p>
                <input
                  type="submit"
                  value={submitLabel}
                  className="btn btn--submit product-details green-btn"
                  disabled={!this.state.formValid}
                />
              </form>
            )}
            {this.state.formSubmitted && (
              <div>
                <div className='thank-you-msg'>{thankYouMsg}</div>
                <button
                  className="product-details green-btn"
                  onClick={this.toggleModal}
                >
                 {closeLbl}
                </button>
              </div>
            )}
          </div>
        </Modal>
      </div>
    )
  }
}

export default Forms
