import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import { LocaleContext } from "../../components/layout"
import Forms from "../../components/forms"
import Tabs from "../../components/tabs"
import SEO from "../../components/seo"

require("../../components/tabs.css")

var Carousel = require("react-responsive-carousel").Carousel

function _onChange() {}
function _onClickItem() {}

function Product({ data: { prismicProduct, site } }) {
  const lang = React.useContext(LocaleContext)
  var i18n = {}
  if (lang.i18n[lang.locale] != undefined) {
    i18n = lang.i18n[lang.locale]
  }
  const [productVariant, setproductVariant] = useState("")

  return (
    <>
    <SEO title={prismicProduct.data.product_name} keywords={[`navigo`, `garmin`]} />
      <div className="container product-container">
        <div className="row" style={{ marginBottom: "20px" }}>
          <div className="col-xs-12 col-sm-4" style={{ textAlign: "center" }}>
            <Carousel
              showArrows={true}
              showThumbs={false}
              infiniteLoop={false}
              emulateTouch
              onClickItem={_onClickItem}
              onChange={_onChange}
            >
              {prismicProduct &&
                prismicProduct.data.image_gallery.map(item => (
                  <div>
                    <img
                      src={item.product_image.url}
                      style={{ maxWidth: "100%", maxHeight: "100%" }}
                    />
                  </div>
                ))}
            </Carousel>
          </div>
          <div
            className="col-xs-12 col-sm-8"
            style={{
              padding: "10px 20px",
            }}
          >
            <div
              style={{
                fontSize: "22px",
                fontWeight: "bold",
                marginBottom: "15px",
              }}
            >
              {prismicProduct.data.product_name}
            </div>
            <div className="product-part-number">
              {prismicProduct.data.part_number}
            </div>
            <div
              style={{
                fontSize: "22px",
                textTransform: "uppercase",
                marginBottom: "20px",
                color: "#525252",
              }}
            >
              {prismicProduct.data.product_price}
            </div>
            {prismicProduct.data.brand_image && <div style={{ maxWidth: "100px", maxHeight:"100px" }}>
              <img src={prismicProduct.data.brand_image.url} style={{ width: "100%", height:"100%" }}></img>
            </div>
            }
            {prismicProduct.data.other_models_prices.length > 0 && (
              <div className="product-variants">
                <p
                  style={{
                    fontSize: "20px",
                    marginBottom: "20px",
                  }}
                >
                  {i18n.other_poduct_models}
                </p>

                {prismicProduct.data.other_models_prices.map(variant => (
                  <div className="product-variants-item">
                    <input
                      style={{
                        fontSize: "16px",
                        marginBottom: "15px",
                        marginRight: "10px",
                        color: "#525252",
                      }}
                      type="radio"
                      name="variants"
                      value={variant.model_price}
                      checked={productVariant === variant.model_price}
                      onChange={() => setproductVariant(variant.model_price)}
                    />
                    {variant.model_price} <br />
                  </div>
                ))}
              </div>
            )}
            <div
              className="col-xs-12 col-sm-4"
              style={{
                marginTop: "20px",
                paddingLeft: "0px",
              }}
            >
              <Forms
                btnLabel={i18n.inquire}
                productTitle={prismicProduct.data.product_name}
                partNumber={prismicProduct.data.part_number}
                productPrice={prismicProduct.data.product_price}
                productVariant={productVariant}
                emailLabel={i18n.email_label}
                submitMessage={i18n.submit_message}
                submitLabel={i18n.submit_label}
                thankYouMsg={i18n.thank_you_msg}
                closeLbl={i18n.close}
                emailjsuser={site.siteMetadata.emailjsuser}
                emailjstemplate={site.siteMetadata.emailjstemplate}
              />
            </div>
          </div>
        </div>
        <div className="row d-none d-md-block">
          <Tabs>
            {prismicProduct.data.tabs &&
              prismicProduct.data.tabs.map(tab => (
                <div label={tab.tab_name}>
                  <div
                    className="col-xs-12 col-sm-12 col-lg-10"
                    dangerouslySetInnerHTML={{
                      __html: tab.tab_content.text,
                    }}
                  />
                </div>
              ))}
          </Tabs>
        </div>

        <div className="row d-sm-none">
          <div {...{ className: "wrapper" }}>
            <ul {...{ className: "accordion-list" }}>
            {prismicProduct.data.tabs &&
              prismicProduct.data.tabs.map((data, key) => {
                return (   
                  <div>       
                  <li {...{ className: "accordion-list__item", key }}>
                    <AccordionItem {...data} />
                  </li>
                  </div> 
                )
              })}
            </ul>
          </div>
        </div>

        {prismicProduct && prismicProduct.data.related_products.length > 0 && (
          <div>
            <h2
              style={{
                margin: `40px auto`,
                textAlign: `center`,
              }}
            >
              {i18n.related_products}
            </h2>
            <div className="row text-center">
              {prismicProduct.data.related_products.map(item => (
                <div className="col-xs-12 col-sm-6 col-md-3 col-lg-4">
                  {item.related_product_link &&
                    item.related_product_link.document.map(product => (
                      <div className="product-box">
                        <div className="product-image">
                          <img
                            className="img-responsive"
                            src={product.data.thumbnail_image.url}
                          />
                        </div>
                        <div className="product-name">
                          {product.data.product_name}
                        </div>
                        <div className="product-price m-b-10 m-t-10">
                          {product.data.product_price}
                        </div>
                        <a href={product.uid}>
                          <div className="product-details">{i18n.details}</div>
                        </a>
                      </div>
                    ))}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default Product

//ToDo "add in query"
// subcategory {
//   document {
//     data {
//       subcategory_name
//     }
//   }
// }
export const pageQuery = graphql`
  query ProductBySlug($uid: String!, $locale: String!) {
    prismicProduct(uid: { eq: $uid }, lang: { eq: $locale }) {
      uid
      data {
        product_name
        part_number
        product_price
        related_products {
          related_product_link {
            document {
              uid
              data {
                product_name
                product_price
                thumbnail_image {
                  url
                }
              }
            }
          }
        }
        tabs {
          tab_name
          tab_content {
            text
          }
        }
        other_models_prices {
          model_price
        }
        image_gallery {
          product_image {
            url
          }
        }
        brand_image {
          url
        }
      }
    }
    site {
      siteMetadata {
        emailjstemplate
        emailjsuser
      }
    }
  }
`
class AccordionItem extends React.Component {
  state = {
    opened: false
  }
  
  render () {
    const {
      props: {
        tab_content,
        tab_name
      },
      state: {
        opened
      }
    } = this
    
    return (
      <div
        {...{
          className: `accordion-item, ${opened && 'accordion-item--opened'}`,
          onClick: () => { this.setState({ opened: !opened }) }
        }}
      >
        <div {...{ className: 'accordion-item__line' }}>
          <h3 {...{ className: 'accordion-item__title' }}>
            {tab_name}
          </h3>
          <span {...{ className: 'accordion-item__icon' }}/>
        </div>
          <div {...{ className: 'accordion-item__inner' }}>
            <div {...{ className: 'accordion-item__content' }}>
              <p {...{ className: 'accordion-item__paragraph' }}>
              <div
                    dangerouslySetInnerHTML={{
                      __html: tab_content.text,
                    }}
                  />
              </p>
            </div>
          </div>
      </div>
    )
  }
}